import { min } from 'moment';
import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../App.css'

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const NavRightMsfr = ({ getParams }) => {
    const date = new Date();
    const UTCStartDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    const UTCEndDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), Math.floor(date.getUTCMinutes() / 10) * 10);
    const [selectedDates, setSelectedDates] = useState([UTCStartDate, UTCEndDate]);

    useEffect(() => {
        if (selectedDates[1] < selectedDates[0]) {
            setSelectedDates([selectedDates[0], selectedDates[0]])
        }
        else {
            getParams({ dates: selectedDates, satellites: null })
        }
    }, [selectedDates, getParams])

    const getStartMaxTime = () => {
        if (UTCStartDate.getTime() === new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate()).getTime()) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
        } else {
            return (new Date()).setHours(23, min = 59)
        }
    }

    const getEndMaxTime = () => {
        if (selectedDates[1] && UTCStartDate.getTime() === new Date(selectedDates[1].getFullYear(), selectedDates[1].getMonth(), selectedDates[1].getDate()).getTime()) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
        } else {
            return (new Date()).setHours(23, min = 59)
        }
    }

    const getEndMinTime = () => {
        if (selectedDates[1] && new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate()).getTime() === new Date(selectedDates[1].getFullYear(), selectedDates[1].getMonth(), selectedDates[1].getDate()).getTime()) {
            return selectedDates[0]
        } else {
            return (new Date()).setHours(0, min = 0)
        }
    }

    const getEndMaxDate = () => {
        var endBound = new Date(selectedDates[0]);
        endBound.setDate(selectedDates[0].getDate() + 5);
        if (endBound > UTCStartDate) {
            return UTCStartDate;
        }
        else {
            return endBound;
        }
    }

    const handleStartDate = (date) => {
        if (new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() === new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate()).getTime()) {
            setSelectedDates([date, selectedDates[1]])
        } else {
            setSelectedDates([date, null])
        }
    }

    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
                <ul>
                <li>Select the region to display by using Google Maps zoom and pan tools or by using the Box
                    Selection function in the Control Panel (lower right corner). Check the Box Selection box to
                    activate and uncheck to deactivate this function.</li>
                <li>Sample product value by clicking on the image</li>
                <li>Toggle the Loop button to start or stop the looping process</li>
                <li>Use the arrows to scroll through the images manually</li>
                <li>Use the slider in the Control Panel to adjust opacity</li>
                <li>Check the Show Counties box to show county boundaries when zoomed in</li>
                </ul>
          </Popover.Body>
        </Popover>
      );
      
    const Instructions = () => (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="primary" style={{fontSize: "20px"}}>Instructions</Button>
        </OverlayTrigger>
    );

    return (
        <div>

            <p><Instructions /></p>

            <h5>
                <a href="https://drive.google.com/file/d/1tIUPEaJL2YmPlBCaV47sFch2x2fuCe5I/view?usp=sharing"
                    target="_blank" rel="noreferrer noopener">mSFR Quick Guide</a>
            </h5>

            <hr style={{ color: "black" }} />
            <h5>Start Date and Time (UTC)</h5>
            <div className="date-picker" style={{ fontSize: "15px" }}>
                <Row>
                    <DatePicker
                        selected={selectedDates[0]}
                        onChange={(date) => handleStartDate(date)}
                        selectsStart
                        startDate={selectedDates[0]}
                        maxDate={UTCStartDate}
                        showYearDropdown
                        yearDropdownItemNumber={2}
                        showTimeSelect
                        timeIntervals={10}
                        minTime={(new Date()).setHours(0, min = 0)}
                        maxTime={getStartMaxTime()}
                        dateFormat="MM/dd/yyyy HH:mm"
                        timeFormat="HH:mm"
                    />
                </Row>
            </div>
            <h5>End Date and Time (UTC)</h5>
            <div className="date-picker" style={{ fontSize: "15px" }}>
                <Row>
                    <DatePicker
                        selected={selectedDates[1]}
                        onChange={(date) => setSelectedDates([selectedDates[0], date])}
                        selectsEnd
                        startDate={selectedDates[0]}
                        endDate={selectedDates[1]}
                        minDate={selectedDates[0]}
                        maxDate={getEndMaxDate()}
                        showYearDropdown
                        yearDropdownItemNumber={2}
                        showTimeSelect
                        timeIntervals={10}
                        minTime={getEndMinTime()}
                        maxTime={getEndMaxTime()}
                        dateFormat="MM/dd/yyyy HH:mm"
                        timeFormat="HH:mm"
                    />
                </Row>
            </div>

            <hr style={{ color: "black" }} />

            <h5 style={{color: "#F00000"}}>New colorbar with max of 0.4 in/hr.</h5>
        </div>
    );
}

export default NavRightMsfr;