import React from 'react';
import Navbar from '../components/Navbar.js'
import { Container, Row, Col } from 'react-bootstrap';


const Publication = () => {
    return (
        <div>
            <Navbar />

            <Container fluid>
                <Row style={{ minWidth: '1000px' }}>

                    {/* <Col className="vh-100" style={{ backgroundColor: "rgb(218, 227, 243)", paddingTop: "20px", maxWidth: '360px' }}>
                    </Col> */}

                    <Col>
                        <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: "16px", paddingTop: "20px" }}>
                            <p>
                                You Y., H. Meng, J. Dong, J.X. Yang, S. Ringerud, Y. Fan, 2022. Precipitation Phase Determination by
                                Brightness Temperatures From ATMS, IEEE Geoscience and Remote Sensing Letters, 19, 1-5, doi:
                                <a href="https://ieeexplore.ieee.org/document/9849662" target="_blank" rel="noreferrer noopener">
                                    10.1109/LGRS.2022.3196386
                                </a>
                            </p>
                            <p>
                                You Y., H. Meng, J. Dong, Y. Fan, R. Ferraro, G. Gu, and L. Wang, 2022. A Snowfall Detection Algorithm
                                for ATMS Over Ocean, Sea Ice, and Coast, IEEE J. of Selected Topics in Applied Earth Obs. and Remote
                                Sens., 15, 1411-1420, doi:
                                <a href="https://ieeexplore.ieee.org/document/9672746" target="_blank" rel="noreferrer noopener">
                                    10.1109/JSTARS.2022.3140768
                                </a>
                            </p>
                            <p>
                                Meng, H., J. Dong, C. Kongoli, R. Ferraro, B. Yan, and L. Zhao, 2020. An operational satellite snowfall rate
                                product at NOAA, IGARSS 2020 Proceedings
                                <a href="http://cics.umd.edu/~vivekag/documents/IGARSS_2020.pdf" target="_blank" rel="noreferrer noopener">
                                    , 5345-5347
                                </a>
                            </p>
                            <p>
                                Kongoli, C., H. Meng, J. Dong and R. Ferraro, 2020. Ground-based assessment of snowfall detection over
                                land using polarimetric high frequency microwave measurements, Remote Sens. 2020, 12, 3441;
                                doi:
                                <a href="https://www.mdpi.com/2072-4292/12/20/3441" target="_blank" rel="noreferrer noopener">
                                    10.3390/rs12203441
                                </a>
                            </p>
                            <p>
                                Meng, H., C. Kongoli, and R.R. Ferraro, 2020. A 1DVAR-based snowfall rate algorithm for passive
                                microwave radiometers, Satellite Precipitation Measurement, Levizzani, V., C. Kidd, D.B. Kirschbaum,
                                C.D. Kummerow, K. Nakamura, F.J. Turk, Eds. Advances in Global Change Research, 67, Springer Nature,
                                Cham, 297-313, doi:
                                <a href="https://link.springer.com/chapter/10.1007/978-3-030-24568-9_17" target="_blank" rel="noreferrer noopener">
                                    10.1007/978-3-030-24568-9_17
                                </a>

                            </p>
                            <p>
                                You, Y., H. Meng, J. Dong, S. Rudlosky, 2019. Time-lag correlation between passive microwave
                                measurements and surface precipitation and its impact on precipitation retrieval evaluation, Geophys.
                                Res. Letter, 46, 8415-8423, doi:
                                <a href="https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2019GL083426" target="_blank" rel="noreferrer noopener">
                                    10.1029/2019GL083426
                                </a>
                            </p>
                            <p>
                                Ferraro, R., H. Meng, B. Zavodsky, S. Kusselson, D. Kann, B. Guyer, A. Jacobs, S. Perfater, M. Folmer, J.
                                Dong, C. Kongoli, B. Yan, N. Wang, and L. Zhao, 2018. Snowfall rates from satellite data help weather
                                forecasters, Eos, 99, doi:
                                <a href="https://eos.org/science-updates/snowfall-rates-from-satellite-data-help-weather-forecasters" target="_blank" rel="noreferrer noopener">
                                    10.1029/2018EO096715
                                </a>
                            </p>
                            <p>
                                Kongoli, C., H. Meng, J. Dong and R. Ferraro, 2018. A hybrid snowfall detection method from satellite
                                passive microwave measurements and global weather forecast models, Quarterly Journal of Royal
                                meteorological Society, 144(S1), 120-132, doi:
                                <a href="https://rmets.onlinelibrary.wiley.com/doi/10.1002/qj.3270" target="_blank" rel="noreferrer noopener">
                                    10.1002/qj.3270
                                </a>
                            </p>
                            <p>
                                Meng, H., J. Dong, R. Ferraro, B. Yan, L. Zhao, C. Kongoli, N. Wang, and B. Zavodsky, 2017. A 1DVAR-
                                based snowfall rate retrieval algorithm for passive microwave radiometers, J. Geophys. Res. Atmos.,
                                122, doi:
                                <a href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1002/2016JD026325" target="_blank" rel="noreferrer noopener">
                                    10.1002/2016JD026325
                                </a>
                            </p>
                            <p>
                                Kongoli, C., H. Meng, J. Dong and R. Ferraro, 2015. A Snowfall detection algorithm over land utilizing
                                high-frequency passive microwave measurements - Application to ATMS. J. Geophys. Res. Atmos.,
                                120(5), 1918-1932. doi:
                                <a href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1002/2014JD022427" target="_blank" rel="noreferrer noopener">
                                    10.1002/2014JD022427
                                </a>
                            </p>
                            <p>
                                Folmer, M. J., M. DeMaria, R. Ferraro; J. Beven; M. Brennan; J. Daniels; R. Kuligowski; H. Meng; S.
                                Rudlosky; L. Zhao; J. Knaff; S. Kusselson; S. D. Miller; T. J. Schmit; C. Velden; and B. Zavodsky, 2015.
                                Satellite tools to monitor and predict Hurricane Sandy (2012): current and emerging products,
                                Atmospheric Research, 166, 165-181. doi:
                                <a href="https://www.sciencedirect.com/science/article/pii/S0169809515001805" target="_blank" rel="noreferrer noopener">
                                    10.1016/j.atmosres.2015.06.005
                                </a>
                            </p>
                            <p>
                                Laviola, S., J. Dong, C. Kongoli, H. Meng, R. Ferraro, and V. Levizzani. 2015: An intercomparison of two
                                passive microwave snowfall detection algorithms over Europe,Proceedings of the Geoscience and
                                Remote Sensing Symposium (IGARSS), IEEE International, Milan, Italy, DOI:
                                <a href="https://ieeexplore.ieee.org/document/7325907" target="_blank" rel="noreferrer noopener">
                                    10.1109/IGARSS.2015.7325907
                                </a>
                            </p>
                        </div>
                    </Col>

                </Row>
            </Container >

        </div >
    );
}
export default Publication;