import React from 'react';
import Navbar from '../components/Navbar.js'
import { Container, Row, Col } from 'react-bootstrap';

const Animations = () => {
    return (
        <div>
            <Navbar />

            <Container fluid>
                <Row style={{ minWidth: '1000px' }}>

                    {/* <Col className="vh-100" style={{ backgroundColor: "rgb(218, 227, 243)", paddingTop: "20px", maxWidth: '360px' }}>
                    </Col> */}

                    <Col>
                        <div style={{ paddingLeft: "40px", paddingTop: "20px", fontSize: "20px" }}>
                            <a href="SFR_202201.gif" target="_blank" rel="noreferrer noopener">
                                January 2, 2022 SFR
                            </a> <p></p>
                            {/* <img src="http://sfr.umd.edu/cases/c20220102_maryland/SFR_202201.gif"></img> */}

                            {/* <br></br><br></br> */}
                            {/* <br></br> */}
                            <a href="msfr_202201.gif" target="_blank" rel="noreferrer noopener">
                                January 2, 2022 mSFR
                            </a> <p></p>
                            {/* <img src="http://sfr.umd.edu/cases/c20220102_maryland/msfr_202201.gif" width="750px"></img> */}
                            <br></br><br></br>
                        </div>
                    </Col>

                </Row>
            </Container>

        </div >
    );
}
export default Animations;