import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Home from './routes/Home';
import MergedSFR from './routes/MergedSFR';
import Animations from './routes/Animations';
import Publication from './routes/Publication';
import Team from './routes/Team';
import SFRAlaska from './routes/SFRAlaska';
import SFRCONUS from './routes/SFRCONUS';
import GenerateGif from './routes/GenerateGif';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(null);

  useEffect(() => {
    switch (searchParams.get('page')) {
      case null:
        setSearchParams({ page: 'Home' });
        break;
      case 'Home':
        setPage(<Home />);
        break;
      case 'SFR-CONUS':
        setPage(<SFRCONUS />);
        break;
      case 'SFR-Alaska':
        setPage(<SFRAlaska />);
        break;
      case 'mSFR-CONUS':
        setPage(<MergedSFR />);
        break;
      case 'Animations':
        setPage(<Animations />);
        break;
      case 'Publication':
        setPage(<Publication />);
        break;
      case 'Team':
        setPage(<Team />);
        break;
      case 'GenerateGif':
        setPage(<GenerateGif />);
        break;
      default:
        setSearchParams({ page: 'Home' });
        break;
    }
  }, [searchParams, setSearchParams])

  return (
    <div>
      {page}
    </div>
  );
}

export default App;
