import React, { useState, useEffect } from 'react';
import { Row, Form } from 'react-bootstrap'
import '../App.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const NavRight = ({ getParams, region }) => {
    const date = new Date();
    const UTCdate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    const [selectedDates, setSelectedDates] = useState([UTCdate, UTCdate]);
    const [satellites, setSatellites] = useState(
        new Array(9).fill(true)
    );

    /* Code for choosing satellites */
    const handleOnChange = (position) => {
        var satellitesCopy = [...satellites];
        satellitesCopy[position] = !satellites[position]
        setSatellites(satellitesCopy)
    }

    useEffect(() => {
        getParams({ dates: selectedDates, satellites: satellites })
    }, [selectedDates, satellites, getParams])

    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
                <ul>
                <li>Select the region to display by using Google Maps zoom and pan tools or by using the Box
                    Selection function in the Control Panel (lower right corner). Check the Box Selection box to
                    activate and uncheck to deactivate this function.</li>
                <li>Sample product value by clicking on the image</li>
                <li>Toggle the Loop button to start or stop the looping process</li>
                <li>Use the arrows to scroll through the images manually</li>
                <li>Use the slider in the Control Panel to adjust opacity</li>
                <li>Check the Show Counties box to show county boundaries when zoomed in</li>
                </ul>
          </Popover.Body>
        </Popover>
      );
      
    const Instructions = () => (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="primary" style={{fontSize: "20px"}}>Instructions</Button>
        </OverlayTrigger>
    );

    return (
        <div>
            {region === "conus"
                ? <p>Images generated using direct broadcast data received at <a href="https://cimss.ssec.wisc.edu/" rel="noreferrer" target="_blank">CIMSS</a>.</p>
                : <p>Images generated using direct broadcast data received at <a href="https://gina.alaska.edu/" rel="noreferrer" target="_blank">GINA</a>.</p>}

            <p><Instructions /></p>

            <h5>
                <a href="https://drive.google.com/file/d/1nhoLAQmk8X9zAKNknAGMDlnNrmGqS_eJ/view?usp=sharing"
                    target="_blank" rel="noreferrer noopener">SFR Quick Guide</a>
            </h5>

            <hr style={{ color: "black" }} />
            
            <h5>Start Date</h5>
            <div className="date-picker" style={{ fontSize: "15px" }}>
                <Row>
                    <DatePicker
                        selected={selectedDates[0]}
                        onChange={(date) => setSelectedDates([date, date])}
                        showYearDropdown
                        selectsStart
                        startDate={selectedDates[0]}
                        yearDropdownItemNumber={4}
                        maxDate={UTCdate}
                    />
                </Row>
            </div>
            <h5>End Date</h5>
            <div className="date-picker" style={{ fontSize: "15px" }}>
                <Row>
                    <DatePicker
                        selected={selectedDates[1]}
                        onChange={(date) => setSelectedDates([selectedDates[0], date])}
                        showYearDropdown
                        selectsEnd
                        startDate={selectedDates[0]}
                        endDate={selectedDates[1]}
                        minDate={selectedDates[0]}
                        yearDropdownItemNumber={4}
                        maxDate={UTCdate}
                    />
                </Row>
            </div>

            <hr style={{ color: "black" }} />

            <Row className='ml-3'>
                <h5>Satellites</h5>
            </Row>
            <Form style={{ margin: "0px" }}>
                <Form.Check
                    inline
                    id = {'n21'}
                    label="NOAA-21"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(5)}
                />
                <Form.Check
                    inline
                    id = {'n20'}
                    label="NOAA-20"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(1)}
                />
                <Form.Check
                    inline
                    id = {'npp'}
                    label="S-NPP"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(0)}
                />
            </Form>
            <Form style={{ margin: "0px" }}>
                <Form.Check
                    inline
                    id = {'n19'}
                    label="NOAA-19"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(2)}
                />
                <Form.Check
                    inline
                    id = {'mob'}
                    label="Metop-B"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(3)}
                />
                <Form.Check
                    inline
                    id = {'moc'}
                    label="Metop-C"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(4)}
                />
            </Form>
            <Form style={{ margin: "0px" }}>
                <Form.Check
                    inline
                    id = {'gpm'}
                    label={"GPM"}
                    type={'checkbox'}
                    defaultChecked="checked"
                    onChange={() => handleOnChange(6)}
                    style={{ width: "90px", fontSize: "14px" }}
                />
                {/*
                <Form.Check
                    inline
                    label="F17"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(7)}
                />
                <Form.Check
                    inline
                    label="F18"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(8)}
                />
                */}
            </Form>
            
            <hr style={{ color: "black" }} />

            <h5 style={{color: "#F00000"}}>New colorbar with max of 0.4 in/hr.</h5>

        </div >
    );
}

export default NavRight;



// function Instructions() {
//     const renderTooltip = (props) => (
//       <Tooltip id="button-tooltip" {...props}>
//             <ul>
//                 <li>Select the region to display by using Google Maps zoom and pan tools or by using the Box
//                     Selection function in the Control Panel (lower right corner). Check the Box Selection box to
//                     activate and uncheck to deactivate this function.</li>
//                 <li>Sample product value by clicking on the image</li>
//                 <li>Toggle the Loop button to start or stop the looping process</li>
//                 <li>Use the arrows to scroll through the images manually</li>
//                 <li>Use the slider in the Control Panel to adjust opacity</li>
//                 <li>Check the Show Counties box to show county boundaries when zoomed in</li>
//             </ul>
//       </Tooltip>
//     );
  
//     return (
//       <OverlayTrigger
//         placement="bottom"
//         delay={{ show: 100, hide: 200 }}
//         overlay={renderTooltip}
//       >
//         <Button variant="success">Display Instructions</Button>
//       </OverlayTrigger>
//     );
//   }



