import React from 'react';
import Navbar from '../components/Navbar.js'
import { Col, Row, Container } from 'react-bootstrap'

const Team = () => {
    return (
        <div>
            <Navbar />
            <Container fluid>

                <Row style={{ minWidth: '1000px' }}>

                    {/* <Col className="vh-100" style={{ backgroundColor: "rgb(218, 227, 243)", paddingTop: "20px", maxWidth: '360px' }}>
                    </Col> */}

                    <Col>
                        <div style={{ textAlign: 'center', paddingTop: "20px" }}>
                        {/* <div style={{ textAlign: 'left', padding: "40px 0px 0px 60px" }}> */}
                            <p><strong>Huan Meng</strong></p> <p>Team Lead, NOAA/NESDIS/STAR</p> <a href="mailto: huan.meng@noaa.gov">huan.meng@noaa.gov</a> <p></p>
                            <br></br>
                            <p><strong>Vivek Agarwal</strong></p> <p>Web Master, University of Maryland</p> <a href="mailto: vivekag@terpmail.umd.edu">vivekag@terpmail.umd.edu</a> <p></p>
                            <br></br>
                            <p><strong>Jun Dong</strong></p> <p>System Development, CISESS</p> <a href="mailto: jundong@umd.edu">jundong@umd.edu</a> <p></p>
                            <br></br>
                            <p><strong>Yongzhen Fan</strong></p> <p>Algorithm Development, CISESS</p> <a href="mailto: yfan1236@umd.edu">yfan1236@umd.edu</a> <p></p>
                            <br></br>
                            <p><strong>Yulan Hong</strong></p> <p>Algorithm Development, CISESS</p> <a href="mailto: yhong126@umd.edu">yhong126@umd.edu</a> <p></p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div >
    );
}
export default Team;