import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap'
import Slider from '@mui/material/Slider';
var turf = require('@turf/turf');

const MapControls = ({ getParams, images, bounds, region }) => {
    const [looping, setLooping] = useState(false);
    const [transparency, setTransparency] = useState(70);
    const [newRectangle, setNewRectangle] = useState(false);
    const [countyBoundaries, setCountyBoundaries] = useState(false);

    const initialState = { index: 0, currRegion: "" };

    /* Reducer allows more complex managing of state. Displays most recent image when first visiting the page */
    function reducer(state, action) {
        switch (action.type) {
            case 'set-index':
                return { index: action.val, currRegion: region };
            case 'new-images':
                if (region !== state.currRegion) {
                    if (images) {
                        return { index: images ? Math.max(images.length - 1, 0) : 0, currRegion: region };
                    }
                    return { index: 0, currRegion: state.region };
                }
                return { index: 0, currRegion: region };
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleIncriment = useCallback((incriment) => {
        if (!images || images.length === 0) {
            dispatch({ type: 'set-index', val: 0 })
        }
        else {
            /* Cycle through indexes until an image intersects with what is currently being viewed */
            const startIndex = state.index;
            var newIndex = (state.index + incriment + images.length) % images.length;
            var poly1 = turf.polygon([images[newIndex][2]]);
            var poly2;
            var poly3;

            // old way is not working, fixed
            bounds = JSON.parse(bounds);

            if (bounds.west < bounds.east) {
                poly2 = turf.polygon([[[bounds.west, bounds.north], [bounds.east, bounds.north], [bounds.east, bounds.south], [bounds.west, bounds.south], [bounds.west, bounds.north]]]);
                poly3 = turf.polygon([[[bounds.west - 360, bounds.north], [bounds.east - 360, bounds.north], [bounds.east - 360, bounds.south], [bounds.west - 360, bounds.south], [bounds.west - 360, bounds.north]]]);
            }
            else {
                poly2 = turf.polygon([[[bounds.west - 360, bounds.north], [bounds.east, bounds.north], [bounds.east, bounds.south], [bounds.west - 360, bounds.south], [bounds.west - 360, bounds.north]]]);
                poly3 = turf.polygon([[[bounds.west, bounds.north], [bounds.east + 360, bounds.north], [bounds.east + 360, bounds.south], [bounds.west, bounds.south], [bounds.west, bounds.north]]]);
            }
            while (!(turf.intersect(poly1, poly2) || turf.intersect(poly1, poly3)) && startIndex !== newIndex) {
                newIndex = (newIndex + incriment + images.length) % images.length;
                poly1 = turf.polygon([images[newIndex][2]]);
            }
            dispatch({ type: 'set-index', val: newIndex })
        }
    }, [state.index, images, bounds])

    useEffect(() => {
        getParams({ index: state.index, transparency: transparency, rectangle: newRectangle, countyBoundaries: countyBoundaries })
    }, [state.index, transparency, newRectangle, getParams, countyBoundaries])

    useEffect(() => {
        dispatch({ type: 'new-images' })
    }, [images])

    /* Create looping effect. Go to next image every 1000ms */
    useEffect(() => {
        if (images && images.length > 0 && looping) {
            const id = setInterval(() => handleIncriment(1), 1000);

            return () => {
                clearInterval(id);
            };
        }
    }, [images, looping, handleIncriment]);

    return (
        <div style={{ marginTop: "10px", marginBottom: "0px", backgroundColor: "#000", paddingTop: "8px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px" }}>
            <Button className="border-secondary" style={{ padding: "0.4rem 0.6rem" }} size="md" variant={looping ? "primary" : "light"} onClick={() => setLooping(!looping)} >Loop</Button>
            ' '
            <ButtonGroup>
                <Button className="border-secondary" variant="light" onClick={() => { handleIncriment(-1); setLooping(false) }} >{'\u21E6'}</Button>
                <Button className="border-secondary" variant="light" onClick={() => { handleIncriment(1); setLooping(false) }} > {'\u21E8'}</Button>
            </ButtonGroup >
            <div style={{ marginTop: "5px", fontSize: "16px", backgroundColor: "#000", marginBottom: "5px" }}>
                <center><font color="#fff">Opacity</font></center>
            </div>
            <div style={{ marginBottom: "10px", backgroundColor: "#fff", paddingLeft: "10px", paddingRight: "10px", paddingTop: "0px", paddingBottom: "0px", width: "150px" }}>
                <Slider defaultValue={70} aria-label="Default" valueLabelDisplay="auto" onChange={(event, newVal) => setTransparency(newVal)} />
            </div>
            <Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 4 }}>
                <Col md="auto" style={{ paddingLeft: 10, paddingRight: 0 }}>
                    <Button className="border-secondary" size="sm" variant={newRectangle ? "primary" : "light"} onClick={() => setNewRectangle(!newRectangle)} >{'\u2B1B'}</Button>
                </Col>
                <Col style={{ fontSize: "16px", paddingTop: "6px", paddingLeft: 8, paddingRight: 0 }}>
                    <font color="#fff">Box Selection</font>
                </Col>
            </Row>
            <Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 4 }}>
                <Col md="auto" style={{ paddingLeft: 10, paddingRight: 0 }}>
                    <Button className="border-secondary" size="sm" variant={countyBoundaries ? "primary" : "light"} onClick={() => setCountyBoundaries(!countyBoundaries)} >{'\u2B1B'}</Button>
                </Col>
                <Col style={{ fontSize: "16px", paddingTop: "6px", paddingLeft: 8, paddingRight: 0 }}>
                    <font color="#fff">Show Counties</font>
                </Col>
            </Row>
        </div>
    )
}

export default MapControls


